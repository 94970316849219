import * as React from "react"
import PropTypes from 'prop-types'
import "./card.scss";

const Card = (props) => {
    const makeid = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const title = (props.title) ? (<h3 className="card-title">{props.title}</h3>) : null;
    const image = (props.image) ? (<img className="card-img-top" src={props.image} alt={props.title} />) : null;
    const icon = (props.icon) ? (<img className="card-icon" src={props.icon} alt="icon" />) : null;
    const singleColumn = (props.singleColumn) ? props.singleColumn : false;

    const hasIcon = () => {
        return (icon) ? true : false;
    }

    const hasSingleColumn = () => singleColumn;

    const cardClasses = () => {
        const classes = ['card', 'my-5'];

        if (hasIcon()) classes.push('card--has-icon');
        if (hasSingleColumn()) classes.push('card--has-single-column'); else classes.push('pr-5');

        return classes.join(' ');
    }


    const description = function () {
        if ((props.description)) {
            return props.description
                .split('\n')
                .map(str => <p className="card-text" key={makeid(11)}>{str}</p>);
        }
        return null;
    }();

    return (
        <article key={makeid(11)} className={cardClasses()}>
            {image}
            <div className="card-body">
                <div className="card-title-wrapper">
                    {icon} {title}
                </div>
                <div className="card-description-wrapper">
                    {description}
                </div>
            </div>
        </article>
    )
}

Card.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    icon: PropTypes.string,
    singleColumn: PropTypes.bool,
}

export default Card;