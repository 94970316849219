import React from "react"
import Footer from "../components/footer/footer"
import Navbar from "../components/navbar/navbar"

export default function DefaultLayout({ children }) {
    return (
        <div>
            <Navbar />
            {children}
            <Footer />
        </div>
    )
}