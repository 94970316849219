import { Link } from "gatsby";
import React, { useState } from "react"
import "./navbar.scss";
import homeIcon from "/static/images/home-icon.svg"

const Navbar = () => {
    const [isActive, setActive] = useState(false);

    const handleToogleMenu = () => {
        setActive(!isActive);
    }

    return (
        <nav className="navbar navbar-dark navbar-expand-lg">
            <div className="container">
                <Link id="logo" className="navbar-brand" to="/" title="Global Family Management SA">
                    <img id="logo-image" className="navbar-brand-logo rotate" src="/images/logo.png" alt="Logo" />
                    <span className="navbar-brand-text">Global Family Management SA</span>
                </Link>
                <button onClick={handleToogleMenu} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={isActive ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="main-menu">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" title="Homepage"><img className="nav-link-icon" src={homeIcon} alt="Home icon" /></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#our-philosophy" title="Our Philosophy">Our Philosophy</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#our-values" title="Our Values">Our Values</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#contact" title="Contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;