import * as React from "react"
import PropTypes from 'prop-types'
import "./hero.scss"


const Hero = (props) => {
    const style = ['hero', 'hero--' + props.variant].join(' ');

    const backgroundImage = () => {
        if (props.backgroundImage) {
            return (<figure className="hero-background">
                <img className="hero-background-image" src={props.backgroundImage} alt={props.title}></img>
            </figure>)
        }
        return null;
    };

    return (
        <div className={style}>
            <div className="container"><h1 className="hero-title">{props.title}</h1></div>
            {backgroundImage()}
        </div>
    )
}

export default Hero;

Hero.propTypes = {
    backgroundImage: PropTypes.string,
    variant: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}