import * as React from "react"
import { Helmet } from "react-helmet"
import { page } from "../data/home"
import DefaultLayout from "../layouts/default"
import Hero from "../components/hero/hero";
import Card from "../components/card/card";
import headerBackgroundImage from "../images/header_backgorund.jpg";
import logoIcon from "/static/images/logo-icon.svg"

const IndexPage = () => {
  return (
    <DefaultLayout>
      <Helmet>
        <title>{page.title}</title>
      </Helmet>
      <section id="hero" className="mb-5">
        <Hero backgroundImage={headerBackgroundImage} variant="primary" title="Smart Investing with Smart Ideas" />
      </section>
      <section id="our-philosophy" className="py-5 section">
        <h2 className="section-title">
          <span className="container d-block">Our Philosophy</span>
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <Card
                icon={logoIcon}
                title={`Family First`}
                description={`Global Family Management SA works only for its clients and their families, with a more personal solution and a quality service of a niche boutique.`}
              />
              <Card
                icon={logoIcon}
                title={`Wealth Management`}
                description={`Global Family Management SA offers bespoke investments, succession, and tax planning solutions in partnership with selected and trusted financial and legal companies.\nGlobal Family Management SA works with a strong expertise, and a professional know-how, to ensure safe custody of assets and relevance for the next generation to preserve and grow its client’s wealth.`}
              />
            </div>
            <div className="col-md-6 col-12">
              <Card
                icon={logoIcon}
                title={`Independence`}
                description={`Global Family Management SA acts independently of all external factors. No ties, no conflicts of interest, to institutions or fund managers.`}
              />
              <Card
                icon={logoIcon}
                title={`Risk Management & Compliance`}
                description={`Global Family Management SA is a member of a self-regulatory organisation, The Swiss Association of Assets Managers, recognized by the Swiss Financial Market Supervisory Authority (FINMA).\nGlobal Family Management SA assumes high standards for Risk Management and Compliance with a full respect of national & international standards of the profession.\nGlobal Family Management SA abides the code of conduct enacted by the Swiss Association of Asset Managers.`}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="global-approach" className="py-5 bg-light section">
        <Hero variant="secondary" title="A global approach by a niche boutique" />
      </section>
      <section id="our-values" className="pt-5 section">
        <h2 className="section-title">
          <span className="container d-block">Our Values</span>
        </h2>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <Card
                singleColumn={true}
                title={`Independence, Dedication, Expertise, Ethical Conduct`}
                description={`Independence, dedication, expertise, ethical conduct. These are the values of Global Family Management SA.\nGlobal Family Management SA always acts in the interests of its clients and their families to offer discretion and professionalism.\nGlobal Family Management SA works to maintain its client’s full confidence in the long term.`}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="py-5 section">
        <h2 className="section-title">
          <span className="container d-block">Contact</span>
        </h2>
        <div className="container">
          <p className="my-5">For further information, please contact us</p>
          <div className="row">
            <div className="col-md-6 col-12 footer-column">
              <strong>Office Address</strong>
              <p>Rue François-Bonivard 4, 1201 Geneva - Switzerland</p>
            </div>
            <div className="col-md-3 col-12 footer-column">
              <strong>Phone</strong>
              <p>+41(0)22 900 14 90</p>
            </div>
            <div className="col-md-3 col-12 footer-column">
              <strong>E-mail Address</strong>
              <p>info@gfamilymgt.com</p>
            </div>
          </div>
        </div>
      </section>

      <Helmet>
        <script type="application/ld+json">
          {
            `
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Geneva",
                "addressRegion": "Geneva",
                "postalCode": "1201",
                "streetAddress": "Rue François-Bonivard 4"
              },
              "description": "Global Family Management SA works only for its clients and their families, with a more personal solution and a quality service of a niche boutique.",
              "name": "Global Family Management SA",
              "telephone": "+41(0)22 900 14 90"
            }
            `
          }
        </script>
      </Helmet>

    </DefaultLayout>
  )
}

export default IndexPage
